<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <logo-text />
        </b-link>

        <b-card-title class="mb-1">
          Wachtwoord instellen 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Zorg dat je wachtwoord lang en sterk genoeg is.
        </b-card-text>

        <!-- form -->
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group
              label="Nieuw wachtwoord"
              label-for="reset-password-new"
            >
             
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                     :class="$v.password.$error ? 'is-invalid' : ''"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Wachtwoord bevestigen"
            >
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="confirmPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                     :class="$v.confirmPassword.$error ? 'is-invalid' : ''"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              :disabled="loading"
              variant="primary"
            >
            <b-spinner v-if="loading" small></b-spinner>
              Nieuw wachtwoord instellen
            </b-button>
          </b-form>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Terug naar aanmelden
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { auth } from "@/firebase/firebaseConfig";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import LogoText from '@core/layouts/components/LogoText.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BSpinner
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    LogoText,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BSpinner
  },
  data() {
    return {
      confirmPassword: '',
      password: '',
      code: null,
      loading: false,
      email: null,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  created(){
    if(this.$route.query.oobCode != null){
      this.code = this.$route.query.oobCode;
       auth.verifyPasswordResetCode(this.code).then((res) => {
         this.email = res;
       }).catch(error => {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Link incorrect',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
         this.$router.push({ name: 'auth-login', query: { email: this.email } });
       })
    }
    else {
      this.$router.push({ name: 'auth-login' });
    }
   
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      if (!this.$v.$invalid) {
        this.loading = true;
        auth.confirmPasswordReset(this.code, this.password).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Wachtwoord ingesteld!',
              text: 'Meld je opnieuw aan',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
          this.$router.push({ name: 'auth-login' });
        }).catch(error => {
           this.$toast({
          component: ToastificationContent,
          props: {
            title: '🤷🏻‍♂️ Er ging iets fout',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
          
      } else {
        this.$v.$touch();
      }
        
      
    },
  },
  validations: {
      password: {
        minLength: minLength(8),
        required,
      },
      confirmPassword: {
        sameAs: sameAs('password'),
      },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
